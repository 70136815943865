<script>
import MessageModal from "@/components/modals/message-modal";

export default {
	components: {
		MessageModal,
	},
	name: "tabs-assignments",
	props: {
		tutorInfo: Object,
	},
	data() {
		return {
            assignments: [],
		};
	},
	mounted() {
        console.log('tutorInfo', this.tutorInfo)
		this.assignments = this.tutorAssignments;
	},
	computed: {
		tutorAssignments() {
			if (this.tutorInfo && this.tutorInfo.assignments.length > 0) {
				try {
					return this.tutorInfo.assignments;
				} catch (error) {
					console.error('Error parsing assignments:', error);
				}
			}
			return [];
		},
	},
	watch: {
		tutorInfo: {
			handler() {
				this.assignments = this.tutorAssignments;
			},
			deep: true
		}
	},
	methods: {
		//
	},
};
</script>

<template>
	<div>
		<MessageModal ref="messageModal" />
		<div class="card" style="border-radius: 0;">
			<div class="card-body p-4" style="box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);">
				<div class="row mb-5">
					<div class="col-12">
                        <table v-if="assignments.length">
							<tr align="center">
                                <th>Assignment ID</th>
								<th>Subject Name</th>
								<th>Assigned Date</th>
								<th></th>
							</tr>
							<tr v-for="assignment in assignments" :key="assignment.id" align="center">
								<td>{{ assignment.assignment_id }}</td>
                                <td>{{ assignment.subject_name }}</td>
								<td>{{ (assignment.assigned_date) }}</td>
								<td>
									<router-link class="mr-2 text-primary tutor_link"
										:to="{ name: 'Assignment Form', params: { id: assignment.assignment_id } }" target="_blank">
										View assignment
									</router-link>
								</td>
							</tr>
						</table>
						<p v-else>No records</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
table {
	max-height: 500px;
	display: block;
	overflow: auto;
}

table tr {
		border-bottom: 1px solid rgba(205, 205, 205, 0.5);
	}

	table th,
	table td {
		padding: 12px;
	}

	& .tutor_link {
		color: #004AAD;
		text-decoration: underline !important;
		cursor: pointer;
	}

	table img {
		cursor: pointer;
	}
</style>